import { LiveChatWidget } from "@livechat/widget-react";
import { Fragment, lazy, Suspense, useMemo } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Loading } from "../components/Loading";
import { useAppUpdateCheck } from "../hooks/useAppUpdateCheck";
import { useOnChange } from "../hooks/useOnChange";
import useOnInitialMount from "../hooks/useOnInitialMount";
import { useParseParams } from "../hooks/useParseParams";
import { useGetUserAction } from "../middleware/useGetUserAction";
import { usePostHogInitiate } from "../middleware/usePostHogInitiate";
import { useQuestionByType } from "../middleware/useQuestionByType";
import { useRefreshToken } from "../middleware/useRefreshToken";
import { tokenState } from "../states/auth";
import { supportVisibilityState } from "../states/header";
import { modularFeedbacksState } from "../states/modularFeedbacks";
import { questionState } from "../states/recording";
import { scoresState } from "../states/score";
import { NotFound } from "../views/NotFound";
import { APP_PATHS } from "./paths";
import { PrivateRoute } from "./PrivateRoute";

const LandingPage = lazy(() => import("../views/auth/LandingPage"));
const EnterEmail = lazy(() => import("../views/auth/EnterEmail"));
const EnterPassword = lazy(() => import("../views/auth/EnterPassword"));
const CreateAccount = lazy(() => import("../views/auth/CreateAccount"));
const EnterVerificationCode = lazy(
  () => import("../views/auth/EnterVerificationCode")
);
const EnterForgotPasswordVerificationCode = lazy(
  () => import("../views/auth/EnterForgotPasswordVerificationCode")
);
const CreateNewPassword = lazy(() => import("../views/auth/CreateNewPassword"));
const SelectJobTitle = lazy(() => import("../views/home/SelectJobTitle"));
const StartAnswering = lazy(
  () => import("../views/home/start_answering/StartAnswering")
);
const Feedback = lazy(() => import("../views/home/feedback/Feedback"));
const SelectQuestionType = lazy(
  () => import("../views/home/SelectQuestionType")
);
const Summary = lazy(() => import("../views/home/Summary"));
const Resources = lazy(() => import("../views/home/Resources"));
const AnswerFeedback = lazy(() => import("../views/home/AnswerFeedback"));
const Analytics = lazy(() => import("../views/home/Analytics"));
const Account = lazy(() => import("../views/home/account/Account"));
const Checkout = lazy(() => import("../views/home/Checkout"));

const OnboardingJobSearch = lazy(
  () => import("../views/onboarding/OnboardingJobSearch")
);
const OnboardingJobDescription = lazy(
  () => import("../views/onboarding/OnboardingJobDescription")
);
const OnboardingFeedback = lazy(
  () => import("../views/onboarding/OnboardingFeedback")
);
const OnboardingResult = lazy(
  () => import("../views/onboarding/OnboardingResult")
);
const Home = lazy(() => import("../views/home/Home"));
const JobCategories = lazy(() => import("../views/home/JobCategories"));
const CreateJobTitle = lazy(() => import("../views/home/CreateJobTitle"));

export function AppRoutes() {
  useParseParams();
  usePostHogInitiate();
  const { pathname } = useLocation();
  const resetScore = useSetRecoilState(scoresState);
  const resetModularFeedbacks = useSetRecoilState(modularFeedbacksState);
  const resetAnswerAnalysis = useSetRecoilState(modularFeedbacksState);
  const resetQuestion = useSetRecoilState(questionState);

  const navigate = useNavigate();
  const token = useRecoilValue(tokenState);
  const { mutate: refreshToken } = useRefreshToken();
  const getUserAction = useGetUserAction();
  const getCommonQuestions = useQuestionByType();
  const appUpdateCheckSnackBar = useAppUpdateCheck();

  const [supportVisibility, setSupportVisibility] = useRecoilState(
    supportVisibilityState
  );

  const isOnboardingCompleted = useMemo(() => {
    return getUserAction.data?.data.isCompleted ?? true;
  }, [getUserAction.data]);

  useOnInitialMount(() => {
    refreshToken({});
    getUserAction.mutate({ actionName: "onboardingComplete" });
    getCommonQuestions.mutate({ questionType: "common" });
  });

  // lets reset the score when the pathname changes
  useOnChange({
    value: pathname,
    defaultValue: "",
    onChange: () => {
      resetScore(null);
      resetModularFeedbacks(null);
      resetAnswerAnalysis(null);
      if (pathname === APP_PATHS.home) {
        resetQuestion(undefined);
      }
    },
  });

  useOnChange({
    value: isOnboardingCompleted,
    defaultValue: true,
    onChange: () => {
      navigate(APP_PATHS.onboardingJobSearch);
    },
  });

  return (
    <Fragment>
      {appUpdateCheckSnackBar}
      <Routes>
        <Route
          path={"/"}
          element={
            <Suspense fallback={<Loading />}>
              {token ? (
                <Navigate to={APP_PATHS.home} />
              ) : (
                <Navigate to={APP_PATHS.welcome} />
              )}
            </Suspense>
          }
        />
        <Route
          path={APP_PATHS.home}
          element={
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path={APP_PATHS.jobCategories}
          element={
            <Suspense fallback={<Loading />}>
              <JobCategories />
            </Suspense>
          }
        />
        <Route
          path={APP_PATHS.createJobTitle}
          element={
            <Suspense fallback={<Loading />}>
              <CreateJobTitle />
            </Suspense>
          }
        />
        <Route
          path={APP_PATHS.welcome}
          element={
            <Suspense fallback={<Loading />}>
              <LandingPage />
            </Suspense>
          }
        />
        <Route
          path={APP_PATHS.onboardingJobSearch}
          element={
            <Suspense fallback={<Loading />}>
              <OnboardingJobSearch />
            </Suspense>
          }
        />
        <Route
          path={APP_PATHS.onboardingJobDescription}
          element={
            <Suspense fallback={<Loading />}>
              <OnboardingJobDescription />
            </Suspense>
          }
        />
        <Route
          path={APP_PATHS.onboardingFeedback}
          element={
            <Suspense fallback={<Loading />}>
              <OnboardingFeedback />
            </Suspense>
          }
        />
        <Route
          path={APP_PATHS.onboardingResult}
          element={
            <Suspense fallback={<Loading />}>
              <OnboardingResult />
            </Suspense>
          }
        />
        <Route
          path={APP_PATHS.enterEmail}
          element={
            <Suspense fallback={<Loading />}>
              <EnterEmail />
            </Suspense>
          }
        />
        <Route
          path={APP_PATHS.enterVerificationCode}
          element={
            <Suspense fallback={<Loading />}>
              <EnterVerificationCode />
            </Suspense>
          }
        />
        <Route
          path={APP_PATHS.enterPassword}
          element={
            <Suspense fallback={<Loading />}>
              <EnterPassword />
            </Suspense>
          }
        />
        <Route
          path={APP_PATHS.createAccount}
          element={
            <Suspense fallback={<Loading />}>
              <CreateAccount />
            </Suspense>
          }
        />
        <Route
          path={APP_PATHS.enterForgotPasswordVerificationCode}
          element={
            <Suspense fallback={<Loading />}>
              <EnterForgotPasswordVerificationCode />
            </Suspense>
          }
        />
        <Route
          path={APP_PATHS.createNewPassword}
          element={
            <Suspense fallback={<Loading />}>
              <CreateNewPassword />
            </Suspense>
          }
        />
        <Route
          path={APP_PATHS.selectJobTitle}
          element={
            <PrivateRoute>
              <SelectJobTitle />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_PATHS.startAnswering}
          element={
            <PrivateRoute>
              <StartAnswering />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_PATHS.feedback}
          element={
            <PrivateRoute>
              <Feedback />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_PATHS.selectQuestionType}
          element={
            <PrivateRoute>
              <SelectQuestionType />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_PATHS.summary}
          element={
            <PrivateRoute>
              <Summary />
            </PrivateRoute>
          }
        />

        <Route
          path={APP_PATHS.analytics}
          element={
            <PrivateRoute>
              <Analytics />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_PATHS.answerFeedback}
          element={
            <PrivateRoute>
              <AnswerFeedback />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_PATHS.resources}
          element={
            <PrivateRoute>
              <Resources />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_PATHS.account}
          element={
            <PrivateRoute>
              <Account />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_PATHS.checkout}
          element={
            <PrivateRoute>
              <Checkout />
            </PrivateRoute>
          }
        />
        <Route path={APP_PATHS.any} element={<NotFound />} />
      </Routes>
      <LiveChatWidget
        license="17154567"
        visibility={supportVisibility}
        onVisibilityChanged={({ visibility }) => {
          if (visibility === "minimized") {
            setSupportVisibility("hidden");
          } else {
            setSupportVisibility(visibility);
          }
        }}
      />
    </Fragment>
  );
}
